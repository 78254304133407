/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import MDTypography from "components/MDTypography";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import MDInput from "components/MDInput";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import React, { useEffect } from "react";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const [models, setModels] = React.useState([{}]);
  const [model, setModel] = React.useState("GPT-4.json");
  const [jsondata, setJsondata] = React.useState({});
  const [chartdata, setChartdata] = React.useState(reportsBarChartData);
  useEffect(() => {
    fetch("models.json")
      .then((res) => res.json())
      .then((data) => {
        console.log("models", data);
        setModels(data);
      });
    loadJson(model);
  }, []);

  const handleDropdown = (event) => {
    setModel(event.target.value);
    loadJson(event.target.value);
  };
  const loadJson = (file) => {
    fetch(file)
      .then((res) => res.json())
      .then((data) => {
        console.log("data", data);
        setJsondata(data);
        //reportsBarChartData["labels"] = data["Category"];
        reportsBarChartData["datasets"][0]["data"] = data["Count"];
        reportsBarChartData["datasets"][0]["datapie"] = data["Percentage"];
        setChartdata({ ...reportsBarChartData, labels: data["Category"] });
        console.log("reportsBarChartData", reportsBarChartData);
      });
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title=""
                  description="Audit Test Distribution"
                  date="campaign sent 2 days ago"
                  chart={chartdata}
                />
              </MDBox>
            </Grid>
            <Grid item xs={24} md={12} lg={8}>
              <Card>
                <MDBox mb={3} p={3}>
                  <Box sx={{ maxWidth: 500 }}>
                    <FormControl fullWidth size="big">
                      <InputLabel id="demo-simple-select-label">Select LLM</InputLabel>
                      <Select
                        style={{ height: 50 }}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select LLM"
                        value={model}
                        onChange={handleDropdown}
                      >
                        {models.map((item, index) => (
                          <MenuItem key={index} value={item.filepath}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </MDBox>
                <SimpleBlogCard
                  title1="Executive Summary"
                  description1={jsondata["Executive_summary"]}
                  title="Test Summary"
                  description={jsondata["Test_Summary"]}
                  action={{
                    type: "external",
                    route: "/report.pdf",
                    color: "info",
                    label: "Download Full Report",
                  }}
                />
              </Card>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
